<template>
  <div class="view-container">
    <div class="staff-form display--flex">
      <div v-if="staff" class="card staff-form__organization display--flex">
        <div>
          <p
            v-if="form.company || (profile && profile.company)"
            class="staff-form__text"
          >
            {{ form.company ? form.company.name : profile.company.name }}
          </p>
        </div>
      </div>
      <div class="card staff-form__staff">
        <div class="card-header card-header-divider">
          {{ getRight("usersList") ? "Пользователь" : "Сотрудник" }}
        </div>
        <div v-if="!isPreloaderLoad" class="card-body">
          <form action="#" @submit.prevent="submitStaff" novalidate>
            <div
              class="staff-form__inputs display--flex justify-content-center"
            >
              <div class="staff-form__col">
                <div
                  v-if="route.params.id"
                  class="staff-form__staff-info-mobile"
                >
                  <p>Дата регистрации: {{ staff.created_at }}</p>
                  <p class="mt-10">
                    Дата последней авторизации: {{ staff.online_from }}
                  </p>
                </div>
                <custom-select-2
                  v-if="
                    getRight('companyDistribution') &&
                    (form.role === 'staff' || form.role === 'curator')
                  "
                  v-model="form.company_id"
                  label="Компания"
                  name="company_id"
                  :options="companyOptions"
                />
                <custom-input
                  v-model="form.email"
                  name="email"
                  label="Email*"
                  type="email"
                  req
                />
                <custom-input
                  v-model="form.password"
                  name="password"
                  :label="route.params.id ? 'Пароль' : 'Пароль*'"
                  autocomplete
                  type="password"
                  req
                  hideble
                />
                <div v-if="profile && (profile.role === 'admin' || profile.role === 'director') && !route.params.id" class="checkbox_wrapper">
                  <custom-checkbox
                      v-model="form.password_change_required"
                      :name="'password_change_required'"
                      :label="'Сменить пароль после первого входа'"
                  />
                </div>
                <hr />
                <custom-input
                  v-model="form.last_name"
                  label="Фамилия"
                  name="last_name"
                  req
                />
                <custom-input
                  v-model="form.first_name"
                  label="Имя*"
                  name="first_name"
                  req
                />
                <custom-input
                  v-model="form.patronymic"
                  label="Отчество"
                  name="patronymic"
                  req
                />
                <hr />
                <custom-select-2
                  v-model="form.role"
                  label="Роль"
                  name="role"
                  :options="roleOptions"
                />
                <div>
                  <b>Сотрудник</b> - роль, которая дает возможности сотруднику
                  проходить тесты и курсы без возможности самому что-либо
                  покупать, самому вступать в группы.
                </div>
                <div v-if="route.params.id" class="staff-form__staff-info">
                  <p>Дата регистрации: {{ staff.created_at }}</p>
                  <p class="mt-10">
                    Дата последней авторизации: {{ staff.online_from }}
                  </p>
                </div>
              </div>
              <div class="staff-form__col">
                <preview-file-loader
                  @loadPhoto="
                    (data) => {
                      form.image = data;
                      form.currentImage = null;
                    }
                  "
                  @delete="
                    () => {
                      form.currentImage = null;
                      form.image = null;
                    }
                  "
                  :image="form.currentImage ? form.currentImage : form.image"
                  class="staff-form__photo-area"
                  label="Фото"
                  name="photo"
                />
                <custom-input
                  v-model="form.age"
                  label="Возраст"
                  name="age"
                  maska="###"
                  type="number"
                  :maxValue="100"
                  :minValue="1"
                />
                <custom-select-2
                  v-model="form.profession_id"
                  label="Профессиональная деятельность"
                  name="profession_id"
                  :options="professionOptions"
                />
                <custom-select-2
                  v-model="form.industry_id"
                  label="Отрасль"
                  name="industry_id"
                  :options="industriesOptions"
                />
                <custom-select-2
                  v-model="form.position_id"
                  label="Уровень должности"
                  name="position_id"
                  :options="positionOptions"
                />
                <custom-select-2
                  v-model="form.city_id"
                  label="Город"
                  name="city_id"
                  :settings="citySettings"
                />
              </div>
            </div>
            <div
              class="
                staff-form__btns
                mt-25
                mb-10
                display--flex
                justify-content-end
              "
            >
              <button type="submit" class="btn btn-accent mr-10">
                Сохранить
              </button>
              <button type="button" @click="router.go(-1)" class="btn btn-w">
                Назад
              </button>
            </div>
            <div class="form-preloader" v-if="isPreloaderSave">
              <preloader />
            </div>
          </form>
        </div>
        <div class="table-preloader" v-else>
          <preloader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import { useStore } from "vuex";
import { reactive, ref } from "@vue/reactivity";
import PreviewFileLoader from "@/components/Forms/Fields/PreviewFileLoader.vue";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import standartError from "../../mixins/standartError";
import CustomRadio from "@/components/Forms/Fields/CustomRadio.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import useErrors from "../../mixins/useErrors";
import useProfileForm from "../../mixins/useProfileForm";
import Preloader from "@/components/Technical/Preloader.vue";
import { notify } from "@kyvg/vue3-notification";
import useCheckRights from "../../mixins/useCheckRights";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";

export default {
  components: {
    CustomCheckbox,
    CustomInput,
    PreviewFileLoader,
    // eslint-disable-next-line vue/no-unused-components
    CustomRadio,
    CustomSelect2,
    Preloader,
  },
  name: "staff-form",
  setup() {
    const store = useStore(),
      route = useRoute(),
      router = useRouter(),
      { setErrors, clearCustomErrors } = useErrors(),
      { setForm } = useProfileForm(),
      { getRight } = useCheckRights(),
      isPreloaderLoad = ref(false),
      isPreloaderSave = ref(false);
    const staff = computed(() => store.state.users.user),
      newStaffId = computed(() => store.state.users.userId),
      profile = computed(() => store.state.profile.profile),
      positionList = computed(() => store.state.test.positionList),
      professionList = computed(() => store.state.test.professionList),
      industriesList = computed(() => store.state.test.industriesList),
      companies = computed(() => store.state.company.companies);
    const form = reactive({
      last_name: null,
      first_name: null,
      patronymic: null,
      age: null,
      sex: null,
      profession_id: null,
      industry_id: null,
      position_id: null,
      city_id: null,
      image: null,
      currentImage: null,
      email: null,
      password: null,
      password_change_required: false,
      company: null,
      role: null,
      company_id: null,
    });

    const professionOptions = ref([]),
      industriesOptions = ref([]),
      positionOptions = ref([]),
      roleOptions = ref([{ id: "staff", text: "Сотрудник" }]),
      companyOptions = ref([]),
      citySettings = ref({
        minimumInputLength: 3,
        delay: 500,
        ajax: {
          url: function (params) {
            return `${process.env.VUE_APP_BACK}/api/addresses/${params.term}`;
          },
          beforeSend: function (request) {
            request.setRequestHeader(
              "Authorization",
              `Bearer ${profile.value.token}`
            );
          },
          processResults: function (data) {
            const results = [];
            for (const [key, value] of Object.entries(data)) {
              results.push({ id: key, text: value });
            }
            return {
              results,
            };
          },
          cache: true,
        },
      });

    const generatePassword = () => {
      let alphabet = "123456789abcdefghijklmnopqrstuvwxyz_-+",
        password = "";
      for (let i = 0; i < 13; i++) {
        password += alphabet[Math.round(Math.random() * (alphabet.length - 1))];
      }
      return password;
    };

    onBeforeMount(() => {
      if (route.params.id) {
        isPreloaderLoad.value = true;
        store.dispatch("users/getUser", route.params.id).then(() => {
          setForm(staff.value, form);
          isPreloaderLoad.value = false;
          if (getRight("companyDistribution")) {
            store.dispatch("company/getCompanies").then(() => {
              companyOptions.value = companies.value.map((company) => {
                return { id: company.id, text: company.name };
              });
            });
          }
        });
      } else {
        form.role = "staff";
        form.password = generatePassword();
      }
      store.dispatch("test/getPositionList").then(() => {
        if (profile.value) {
          if (profile.value.role == "director")
            roleOptions.value = [
              { id: "staff", text: "Сотрудник" },
              { id: "curator", text: "Куратор" },
            ];
          if (profile.value.role == "admin")
            roleOptions.value = [
              { id: "staff", text: "Сотрудник" },
              { id: "curator", text: "Куратор" },
              { id: "admin", text: "Суперадмин" },
              { id: "director", text: "Администратор" },
              { id: "private_person", text: "Частное лицо" },
            ];
        }
        positionOptions.value = positionList.value.map((position) => {
          return { id: position.id, text: position.text };
        });
      });
      store.dispatch("test/getProfessionList").then(() => {
        professionOptions.value = professionList.value.map((profession) => {
          return { id: profession.id, text: profession.text };
        });
      });
      store.dispatch("test/getIndustriesList").then(() => {
          industriesOptions.value = industriesList.value.map((industry) => {
              return { id: industry.id, text: industry.text };
          });
      });
    });

    const submitStaff = () => {
      if (form.age && (form.age <= 0 || form.age > 100)) {
        notify({
          type: "error",
          title: "Возраст должен быть числом от 1 до 100",
        });
        return;
      }
      isPreloaderSave.value = true;
      if (route.params.id) {
        store
          .dispatch("users/updateUser", {
            id: route.params.id,
            form: { ...form },
          })
          .then(() => (isPreloaderSave.value = false))
          .catch(standartError(setErrors, clearCustomErrors, isPreloaderSave));
        return;
      }
      store
        .dispatch("users/createUser", { ...form })
        .then(() => {
          if (route.params.group_id) {
            let userId = store.state.users.userId;
            if (userId) {
              store.
                dispatch("group/addUserToGroup", {
                  groupId: route.params.group_id,
                  userId: Number(userId),
                }).then(() => {
                  isPreloaderSave.value = false;
                  router.go(-1);
                });
            }
          } else {
            isPreloaderSave.value = false;
            router.go(-1);
          }
        })
        .catch((error) => {
          isPreloaderSave.value = false;
          Object.keys(error.response.data).map(function (key) {
            notify({
              title: "Ошибка",
              text: error.response.data[key][0],
              type: "error",
            });
          });
        });
    };

    return {
      form,
      submitStaff,
      staff,
      route,
      professionOptions,
      roleOptions,
      companyOptions,
      citySettings,
      positionOptions,
      router,
      profile,
      newStaffId,
      isPreloaderLoad,
      isPreloaderSave,
      getRight,
      industriesOptions
    };
  },
};
</script>

<style lang="scss" scoped>
.checkbox_wrapper {
  margin-left: 15px;

  @media (max-width: 1000px) {
    margin-top: 15px;
  }
}
.staff-form {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 769px) {
    .form-group {
      margin: 25px -15px;
    }
  }

  &__radios {
    margin-top: 25px;

    .form-group:not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__organization {
    flex: 0 1 20%;
    padding: 25px 40px 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__staff {
    flex: 1 0 80%;
  }

  &__staff,
  &__organization {
    margin-bottom: 0;
  }

  &__title {
    display: block;
    font-size: 26px;
    padding-bottom: 10px;
  }

  &__text {
    font-size: 27px;
    display: block;
    padding-bottom: 10px;
    max-width: 90%;
  }

  &__col {
    flex: 0 1 50%;
    padding: 0 10px;
  }

  &__staff-info-mobile {
    display: none;
  }

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 1400px) {
    &__inputs {
      flex-direction: column;
    }
    &__staff-info {
      display: none;
    }
    &__staff-info-mobile {
      display: block;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 425px) {
    &__photo-area {
      margin: 0 !important;
    }
    &__organization {
      padding: 20px 20px 0;
    }
  }
}
</style>